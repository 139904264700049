// project specific CSS goes here

////////////////////////////////
//Variables//
////////////////////////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////////////////////////
//Alerts//
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 20px;
}

p,
div {
  font-family: 'garamond-premier-pro-display';
}

.nameblock {
  text-align: center;
}

.box {
  border: thin solid black;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

img {
  width: 100%;
  height: auto;
}

@import '../../../node_modules/bootstrap/scss/bootstrap';
